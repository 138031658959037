import { useFullScreenCanvas } from '@/utils/hooks/useFullScreenCanvas';
import { useEffect } from 'react';
import * as THREE from 'three';
import GUI from 'lil-gui';
import gsap from 'gsap';
import { load3DFont } from '@/utils/load3DFont';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';

function initCanvas({
	sw,
	sh,
	updateRequestAnimationFrameId,
}: {
	sw: number;
	sh: number;
	updateRequestAnimationFrameId: (newId: number) => void;
}) {
	const scene = new THREE.Scene();
	const camera = new THREE.PerspectiveCamera(75, sw / sh, 0.1, 100);
	camera.position.set(2, 2, 4);
	camera.lookAt(new THREE.Vector3(0, 0, 0));
	scene.add(camera);

	const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
	scene.add(ambientLight);
	const pointLight = new THREE.PointLight(0xffffff, 0.5);
	pointLight.position.set(2, 3, 4);

	const renderer = new THREE.WebGLRenderer({ canvas: webgl });
	renderer.setSize(sw, sh);

	// #region texture loader
	// const textureLoader = new THREE.TextureLoader();
	const getTextureURL = (name: string) => `/3d/texture/${name}`;

	// const doorColorTexture = textureLoader.load(getTextureURL('door/color.jpg'));
	// const doorAlphaTexture = textureLoader.load(getTextureURL('door/alpha.jpg'));
	// const doorAmbientOcclusionTexture = textureLoader.load(
	// 	getTextureURL('door/ambientOcclusion.jpg'),
	// );
	// const doorHeightTexture = textureLoader.load(getTextureURL('door/height.jpg'));
	// const doorNormalTexture = textureLoader.load(getTextureURL('door/normal.jpg'));
	// const doorMetalnessTexture = textureLoader.load(getTextureURL('door/metalness.jpg'));
	// const doorRoughnessTexture = textureLoader.load(getTextureURL('door/roughness.jpg'));
	// const matcapTexture = textureLoader.load(getTextureURL('matcaps/1.png'));
	// const gradientTexture = textureLoader.load(getTextureURL('gradients/3.jpg'));

	// Environment maps
	const cubeTextureLoader = new THREE.CubeTextureLoader();
	// order matters!
	const environmentMapTexture = cubeTextureLoader.load([
		getTextureURL('environmentMaps/0/px.jpg'),
		getTextureURL('environmentMaps/0/nx.jpg'),
		getTextureURL('environmentMaps/0/py.jpg'),
		getTextureURL('environmentMaps/0/ny.jpg'),
		getTextureURL('environmentMaps/0/pz.jpg'),
		getTextureURL('environmentMaps/0/nz.jpg'),
	]);

	// #endregion

	// const mainMaterial = new THREE.MeshBasicMaterial({ map: doorColorTexture });
	// const mainMaterial = new THREE.MeshNormalMaterial();
	// const mainMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });

	const mainMaterial = new THREE.MeshStandardMaterial();
	mainMaterial.metalness = 0.7;
	mainMaterial.roughness = 0.2;
	mainMaterial.envMap = environmentMapTexture;

	const sphereGeometry = new THREE.SphereGeometry(0.5, 16, 16);
	const planeGeometry = new THREE.PlaneGeometry(1, 1);
	const torusGeometry = new THREE.TorusGeometry(0.3, 0.2, 16, 32);

	const sphere = new THREE.Mesh(sphereGeometry, mainMaterial);
	sphere.position.set(-2, 0, 0);
	const plane = new THREE.Mesh(planeGeometry, mainMaterial);
	const torus = new THREE.Mesh(torusGeometry, mainMaterial);
	torus.position.set(2, 0, 0);
	scene.add(sphere, plane, torus);

	// #region gui
	const gui = new GUI();

	const guiActions = {
		spin: () => {
			gsap.to(torus.rotation, { duration: 1, y: torus.rotation.y + Math.PI * 2 });
		},
	};
	gui.add(guiActions, 'spin');
	gui.add(mainMaterial, 'wireframe');
	// #endregion

	(async function initAsync() {
		const fontHelvetiker = await load3DFont('helvetiker');
		const textGeometry = new TextGeometry('Ambient Occlusion', {
			font: fontHelvetiker,
			size: 0.5,
			height: 0.2,
			curveSegments: 12,
			bevelEnabled: true,
			bevelThickness: 0.03,
			bevelSize: 0.01,
			bevelOffset: -0.01,
			bevelSegments: 6,
		}).center();
		const textMain = new THREE.Mesh(textGeometry, new THREE.MeshBasicMaterial({ color: 0xa0a0f0 }));
		textMain.position.y = 1.5;
		scene.add(textMain);

		// #region gui
		gui.add(textMain.rotation, 'y', 0, Math.PI * 2, 0.01);
		// #endregion
	})();

	const clock = new THREE.Clock();
	function render() {
		const elapsedTime = clock.getElapsedTime();

		plane.rotation.y = elapsedTime * 0.1;
		// torus.rotation.y = elapsedTime * 0.1;
		sphere.rotation.y = elapsedTime * 0.1;

		plane.rotation.x = elapsedTime * 0.1;
		torus.rotation.x = elapsedTime * 0.1;
		sphere.rotation.x = elapsedTime * 0.1;

		renderer.render(scene, camera);
		updateRequestAnimationFrameId(requestAnimationFrame(render));
	}
	render();
	return { scene, camera, renderer };
}

export const ThreeJSGalaxyRoute = () => {
	const size = useFullScreenCanvas();

	useEffect(() => {
		let requestAnimationFrameId = 0;
		initCanvas({
			...size,
			updateRequestAnimationFrameId: (newId) => {
				requestAnimationFrameId = newId;
			},
		});

		return () => {
			cancelAnimationFrame(requestAnimationFrameId);
		};
	}, [size]);

	return (
		<div>
			<canvas id="webgl" width={size.sw} height={size.sh} />
		</div>
	);
};
