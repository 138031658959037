// @ts-nocheck

import { useRef, useEffect } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useQuery } from '@tanstack/react-query';
import { KEYS } from '@/services/query';

export function KG3D(props) {
	const { data: currentAnim = 'Idle' } = useQuery({ queryKey: KEYS.meAction });
	const groupRef = useRef();
	const { nodes, materials, animations } = useGLTF('/3d/kg.glb');
	const { actions, names } = useAnimations(animations, groupRef);

	useEffect(() => {
		const anim = actions[currentAnim];
		anim?.reset().fadeIn(0.5).play();
		return () => {
			anim.fadeOut(0.5);
		};
	}, [currentAnim]);

	return (
		<group ref={groupRef} {...props} dispose={null}>
			<group name="Scene">
				<group name="KG" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
					<group name="Kachujin">
						<skinnedMesh
							name="Kachujin_1"
							geometry={nodes.Kachujin_1.geometry}
							material={materials.kachujin_MAT}
							skeleton={nodes.Kachujin_1.skeleton}
						/>
						<skinnedMesh
							name="Kachujin_2"
							geometry={nodes.Kachujin_2.geometry}
							material={materials.kachujin_MAT_}
							skeleton={nodes.Kachujin_2.skeleton}
						/>
					</group>
					<primitive object={nodes.mixamorigHips} />
				</group>
			</group>
		</group>
	);
}

useGLTF.preload('/3d/kg.glb');
