import { SpaceshipBarbaraTheBee } from '@/components/3d-models/SpaceshipBarbaraTheBee.3d';
import {
	Float,
	ScrollControls,
	useScroll,
	PerspectiveCamera,
	Scroll,
	Points,
	PointMaterial,
} from '@react-three/drei';
import { button, useControls } from 'leva';
import * as THREE from 'three';
import gsap from 'gsap';
import { random } from 'maath';

import { Moon3D } from '@/components/3d-models/Moon.3d';
import { useFrame } from '@react-three/fiber';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { Planet3 } from '@/components/3d-models/Planet3.3d';
import { Planet2 } from '@/components/3d-models/Planet2.3d';
import { IntroSections } from './IntroSections';
import { Planet1 } from '@/components/3d-models/Planet1.3d';

const _AtmosScrollExperience = () => {
	const spaceshipRef = useRef<THREE.Group | null>(null);

	const tl = useRef<gsap.core.Timeline | null>(null);
	const controls = useControls('spaceship', {
		ry: {
			value: Math.PI * 0.95,
			min: -Math.PI,
			max: Math.PI,
		},
		zoom: false,
		debug: button(() => {
			console.log('duration', tl.current?.duration());
		}),
	});

	const scroll = useScroll();
	useFrame(({ camera }) => {
		tl.current?.seek(scroll.offset * tl.current.duration());
		camera.lookAt(spaceshipRef.current!.position);
	});

	useLayoutEffect(() => {
		tl.current = gsap.timeline({ defaults: { duration: 2, ease: 'none' } });
		tl.current
			.to(spaceshipRef.current!.position, {
				z: -20,
				x: -2,
			})
			.to(spaceshipRef.current!.position, {
				z: -40,
				x: 2,
			})
			.to(spaceshipRef.current!.position, {
				z: -60,
				x: -3,
			})
			.to(spaceshipRef.current!.position, {
				z: -80,
				x: 0,
			});
	}, []);

	return (
		<>
			<ambientLight intensity={1.8} />

			<group ref={spaceshipRef}>
				<Float rotationIntensity={0.5}>
					<SpaceshipBarbaraTheBee scale={0.16} position={[0, -1.4, 1]} rotation-y={controls.ry} />
				</Float>
				<Stars />
				<PerspectiveCamera makeDefault position={[0, 0, 10]} />
			</group>

			<Planet2 position={[-2, 1, -3]} scale={0.3} />
			<Moon3D position={[0, 0.6, -20]} scale={0.5} speed={-1} />
			<Planet3 position={[2, -5, -40]} scale={0.3} speed={3} />
			<Moon3D position={[0, 0.8, -50]} scale={0.3} />
			<Planet1 position={[0, 2.8, -90]} speed={2} scale={1.6} />
		</>
	);
};

export const AtmosScrollExperience = () => {
	useEffect(() => {
		const canvas = document.querySelector('canvas')!;
		canvas.style.background =
			'linear-gradient(180deg, #020617 0%, #262626 66%, rgba(1,3,25,0.5) 100%)';

		return () => {
			canvas.style.background = '';
		};
	}, []);
	return (
		<ScrollControls eps={0.01} pages={5} damping={0.5}>
			<_AtmosScrollExperience />
			<Scroll html>
				<IntroSections />
			</Scroll>
		</ScrollControls>
	);
};

const sphere = random.inSphere(new Float32Array(5000), { radius: 150 }) as Float32Array;
const STARS_PARAMS = {
	initialZ: -150,
};
const Stars = () => {
	const ref = useRef<any>();
	const groupRef = useRef<THREE.Group | null>(null);
	const scroll = useScroll();

	useFrame((_, delta) => {
		ref.current.rotation.x -= delta / 100;
		ref.current.rotation.y -= delta / 90;
		groupRef.current!.position.z = scroll.range(0, 1) * 15 + STARS_PARAMS.initialZ;
	});
	return (
		<group ref={groupRef} rotation={[0, 0, Math.PI / 4]} position-z={STARS_PARAMS.initialZ}>
			<Points ref={ref} positions={sphere} stride={3} frustumCulled={false}>
				<PointMaterial
					transparent
					opacity={0.8}
					size={0.4}
					// transmission={1}
					roughness={0.5}
					metalness={0.5}
					sizeAttenuation={true} // further away, smaller
					depthWrite={false}
				/>
			</Points>
		</group>
	);
};
