import { useEffect, useReducer } from 'react';

export const useFullScreenCanvas = () => {
	const reducer = (
		state: { sw: number; sh: number },
		action: { type: string; payload: { sw: number; sh: number } },
	) => {
		switch (action.type) {
			case 'update':
				return { ...state, ...action.payload };
			default:
				return state;
		}
	};

	const [size, dispatch] = useReducer(reducer, { sw: window.innerWidth, sh: window.innerHeight });

	useEffect(() => {
		const resizeEventListener: EventListener = () => {
			dispatch({ type: 'update', payload: { sw: window.innerWidth, sh: window.innerHeight } });
		};

		window.addEventListener('resize', resizeEventListener);

		return () => {
			window.removeEventListener('resize', resizeEventListener);
		};
	}, []);

	return size;
};
