import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';

const _queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
		},
	},
	queryCache: new QueryCache({
		onError: (err) => {
			console.error('= = => queryCache.error:', err);
		},
	}),
});

export function QueryProvider({ children }: { children: React.ReactNode }) {
	return <QueryClientProvider client={_queryClient}>{children}</QueryClientProvider>;
}

export const KEYS = {
	meAction: ['meAction'],
	chairMaterial: ['chairMaterial'],
};
