import {
	Box,
	KeyboardControls,
	KeyboardControlsEntry,
	OrbitControls,
	Text3D,
	useKeyboardControls,
} from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import { Physics, RapierRigidBody, RigidBody, quat } from '@react-three/rapier';
import { Reducer, Suspense, useMemo, useReducer, useRef, useState } from 'react';
import * as THREE from 'three';

enum Controls {
	forward = 'forward',
	back = 'back',
	left = 'left',
	right = 'right',
	jump = 'jump',
}

export const GameJumpRoute = () => {
	const [score, dispatch] = useReducer(reducer, 0);
	const map = useMemo<KeyboardControlsEntry<Controls>[]>(
		() => [
			{ name: Controls.forward, keys: ['ArrowUp', 'KeyW'] },
			{ name: Controls.back, keys: ['ArrowDown', 'KeyS'] },
			{ name: Controls.left, keys: ['ArrowLeft', 'KeyA'] },
			{ name: Controls.right, keys: ['ArrowRight', 'KeyD'] },
			{ name: Controls.jump, keys: ['Space'] },
		],
		[],
	);
	return (
		<>
			<KeyboardControls map={map}>
				<Canvas shadows camera={{ position: [10, 10, 10], fov: 50 }}>
					<color attach="background" args={['papayawhip']} />
					<Suspense fallback={null}>
						<GameScene
							incrementScore={() => dispatch({ type: 'increment' })}
							resetScore={() => dispatch({ type: 'reset' })}
						/>
					</Suspense>
				</Canvas>
			</KeyboardControls>
			<div className="fixed left-4 top-4 p-4 text-xl font-bold uppercase text-slate-800">
				Score: {score}
			</div>
		</>
	);
};

const reducer: Reducer<number, { type: 'increment' | 'reset' }> = (s, a) => {
	switch (a.type) {
		case 'increment':
			return s + 1;
		case 'reset':
			return 0;
		default:
			return s;
	}
};

type GameSceneProps = {
	incrementScore: () => void;
	resetScore: () => void;
};

const GameScene = ({ incrementScore, resetScore }: GameSceneProps) => {
	const [hover, setHover] = useState(false);
	const [started, setStarted] = useState(false);

	const cube = useRef<RapierRigidBody>(null);
	const kicker = useRef<RapierRigidBody>(null);
	const jumpPressed = useKeyboardControls<Controls>((s) => s.jump);

	const jump = () => {
		incrementScore();
		cube.current?.applyImpulse({ x: 0, y: 5, z: 0 }, true);
	};

	useFrame((_, delta) => {
		if (!started) return;

		const y = cube.current?.translation().y;
		if (y && y < -30) {
			resetScore();
			setStarted(false);
			cube.current.setRotation(quat(), true);
			cube.current?.setTranslation({ x: -2.5, y: 1, z: 0 }, true);
			kicker.current?.setNextKinematicRotation(quat());
			return;
		}

		if (jumpPressed) jump();

		const curRotation = quat(kicker.current?.rotation());
		const incRotation = new THREE.Quaternion().setFromAxisAngle(
			new THREE.Vector3(0, 1, 0),
			2 * delta,
		);
		curRotation.multiply(incRotation);
		kicker.current?.setNextKinematicRotation(curRotation);
	});

	return (
		<>
			<Text3D font="/3d/font/xi.json" position={[-2.2, 5, 2.2]} rotation={[-0.11, 0.8, 0.06]}>
				习得文武艺
				<meshNormalMaterial />
			</Text3D>

			<Physics>
				<ambientLight intensity={0.5} />
				<directionalLight intensity={0.5} position={[-10, 10, 1]} />
				<OrbitControls />

				<RigidBody ref={cube} position={[-2.5, 1, 0]}>
					<Box
						args={[1, 1, 1]}
						onPointerEnter={() => {
							setHover(true);
						}}
						onPointerLeave={() => {
							setHover(false);
						}}
						onClick={() => {
							setStarted(true);
							jump();
						}}
					>
						<meshStandardMaterial color={hover ? 'hotpink' : 'royalblue'} />
					</Box>
				</RigidBody>

				<RigidBody ref={kicker} type="kinematicPosition" position={[0, 0.75, 0]}>
					<group position={[2.5, 0, 0]}>
						<Box args={[5, 0.5, 0.5]}>
							<meshStandardMaterial color="peachpuff" />
						</Box>
					</group>
				</RigidBody>

				<RigidBody type="fixed">
					<Box args={[10, 1, 10]} position={[0, 0, 0]}>
						<meshStandardMaterial color="springgreen" />
					</Box>
				</RigidBody>
			</Physics>
		</>
	);
};
