import { Points, PointMaterial } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { random } from 'maath';

export function Stars3D(props: any) {
	const ref = useRef<any>();
	const sphere = useRef(random.inSphere(new Float32Array(5000), { radius: 1.5 })).current;

	useFrame((_, delta) => {
		ref.current.rotation.x -= delta / 100;
		ref.current.rotation.y -= delta / 90;
	});
	return (
		<group rotation={[0, 0, Math.PI / 4]}>
			<Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
				<PointMaterial
					transparent
					color="#ff000020"
					size={0.004}
					sizeAttenuation={true} // further away, smaller
					depthWrite={false}
				/>
			</Points>
		</group>
	);
}
