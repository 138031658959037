import { Canvas, useThree } from '@react-three/fiber';
import { Leva, button, useControls } from 'leva';
import { MacResumeExperience } from './mac-resume/MacResumeExperience';
import { AtmosScrollExperience } from './home-experience/Home3D.experience';
import { ChatBot } from '@/ui/widgets';

declare global {
	var portal3d: HTMLDivElement;
}

export const Index3D = () => {
	return (
		<div className="h-screen">
			<Leva collapsed hidden={!import.meta.env.DEV} />
			<Canvas
				className="bg-gradient-to-bl from-slate-950 to-neutral-800"
				// camera={{ position: [-3, 1.5, 4] }}
			>
				<ExperienceWithControls />
			</Canvas>
			<div id="portal3d" className="pointer-events-none absolute left-0 top-0 h-screen w-screen" />
			<ChatBot />
		</div>
	);
};

const ExperienceWithControls = () => {
	const { camera } = useThree();

	const { Experience, axesHelper } = useControls({
		Experience: {
			options: {
				AtmosScrollExperience,
				MacResumeExperience,
			},
		},
		resetCamera: button(() => {
			camera.position.set(0, 0, 5);
			camera.rotation.set(0, 0, 0);
		}),
		axesHelper: false,
	});

	return (
		<>
			<axesHelper args={[3]} visible={axesHelper} />
			<Experience />
		</>
	);
};
