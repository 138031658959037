import { C } from '@/config/constants';
import { useEffect, useRef, useState } from 'react';
import { Manager } from 'socket.io-client';

declare global {
	var chatBotInput: HTMLInputElement;
}

// TODO: add query?
const manager = new Manager(C.SOCKET_URL, {
	path: '/api/socket/io',
	reconnectionDelayMax: 10000,
}).on('error', (err) => {
	console.log('connection error:', err);
});

export const ChatBot = () => {
	const socketRef = useRef<ReturnType<typeof manager.socket>>();
	const [msg, setMsg] = useState('Tap me to chat');

	useEffect(() => {
		return () => {
			socketRef.current?.disconnect();
		};
	}, []);

	return (
		<div className="fixed bottom-0 right-0">
			<img
				src="/img/me.png"
				className="w-[150px] transition-all active:w-[155px]"
				onClick={() => {
					if (socketRef.current?.connected) return;

					// TODO: auth token?
					socketRef.current = manager.socket('/', {
						auth: {
							token: '123',
						},
					});

					socketRef.current
						.on('connect', () => {
							setMsg("Hi, I'm connected!");
						})
						.on('connect_error', (err) => {
							setMsg(`Umm, connection failed with error: ${err.message || 'unknown'}`);
						})
						.on('support-chat-message', (msg) => {
							setMsg(msg.message || '');
						});
				}}
			/>
			{!!msg && (
				<div className="absolute -top-8 right-3/4 min-w-max">
					<div className="chat chat-end w-full">
						<div className="chat-bubble ml-0 w-full max-w-full">{msg}&nbsp;</div>
					</div>
					{socketRef.current?.connected && (
						<div className="mt-1 flex w-full gap-2">
							<input
								id={'chatBotInput'}
								type="text"
								placeholder="Type here"
								className="input rounded-md"
							/>
							<button
								className="btn btn-circle"
								onClick={() => {
									socketRef.current?.emit('message', 'Hello world!');
									const msg = chatBotInput.value;
									if (msg) {
										socketRef.current?.emit(
											'support-chat-message',
											{ message: msg },
											{ token: '123' },
										);
										chatBotInput.value = '';
									}
								}}
							>
								<IconSend />
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const IconSend = () => (
	<svg
		viewBox="0 0 1024 1024"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		p-id="4218"
		width="24"
		height="24"
		className="mr-0.5 mt-0.5"
	>
		<path
			d="M832.170667 88.618667a82.944 82.944 0 0 1 82.346666 21.589333c21.461333 21.76 29.397333 53.632 20.650667 83.072l-53.290667 179.285333a32 32 0 1 1-61.312-18.432l53.290667-179.328a19.029333 19.029333 0 0 0-4.778667-19.285333 18.944 18.944 0 0 0-19.114666-4.992L163.370667 350.165333a18.901333 18.901333 0 0 0-13.824 16.085334 19.029333 19.029333 0 0 0 9.088 19.2l144.469333 88.917333a32.341333 32.341333 0 0 1 10.581333 44.330667 31.957333 31.957333 0 0 1-43.946666 10.666666l-144.469334-88.96a82.986667 82.986667 0 0 1-39.168-82.773333 82.730667 82.730667 0 0 1 59.52-69.418667l686.506667-199.594666zM769.194667 526.933333c5.12-17.066667 22.912-26.794667 39.850666-21.632 16.938667 5.12 26.581333 23.04 21.504 40.106667l-99.072 333.397333a82.517333 82.517333 0 0 1-69.290666 59.178667 82.090667 82.090667 0 0 1-81.834667-39.637333l-174.933333-289.706667a32.384 32.384 0 0 1 4.693333-39.466667l248.405333-249.984a31.829333 31.829333 0 0 1 45.226667 0 32.341333 32.341333 0 0 1 0 45.568l-230.570667 232.106667 161.877334 268.032a18.773333 18.773333 0 0 0 18.986666 9.216 18.901333 18.901333 0 0 0 16.085334-13.738667l99.072-333.44z"
			fill="#ffffff"
			p-id="4219"
		></path>
	</svg>
);
