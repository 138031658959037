// @ts-nocheck

import { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GroupProps } from '@react-three/fiber';

export function SpaceshipBarbaraTheBee(props: GroupProps) {
	const { nodes, materials } = useGLTF('/3d/models/Spaceship_BarbaraTheBee.gltf');

	return (
		<group {...props} dispose={null}>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Spaceship_BarbaraTheBee.geometry}
				material={materials.Atlas}
			/>
		</group>
	);
}

useGLTF.preload('/3d/models/Spaceship_BarbaraTheBee.gltf');
