import { BuilderComponent, useIsPreviewing, builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import { Loading } from '@/components';
import { Play3D } from './play-3d.route';
import { StarsRoute } from './3d/stars.route';
import { GameJumpRoute } from './games/jump.route';
import { ThreeJSBasicRoute } from './3d/threejs/basics.route';
import { ThreeJSTextRoute } from './3d/threejs/text.route';
import { ThreeJSGalaxyRoute } from './3d/threejs/galaxy.route';
import { ThreeJSHomeRoute } from './3d/threejs/home.route';
import { Index3D } from './3d/index.route';

builder.init(import.meta.env.VITE_BUILDER_API_KEY);

const NotFound = () => <h1>No page found for this URL, did you publish it?</h1>;
const CatchAllRoute = () => {
	const isPreviewingInBuilder = useIsPreviewing();
	const [notFound, setNotFound] = useState(false);
	const [content, setContent] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchContent() {
			setLoading(true);
			const content = await builder
				.get('page', {
					url: window.location.pathname,
				})
				.promise()
				.finally(() => setLoading(false));

			setContent(content);
			setNotFound(!content);

			if (content?.data.title) {
				document.title = content.data.title;
			}
		}
		fetchContent();
	}, [window.location.pathname]);

	if (loading) return <Loading />;

	if (notFound && !isPreviewingInBuilder) return <NotFound />;
	if (content === null) return <div>Content is null</div>;
	return <BuilderComponent model="page" content={content} />;
};

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Index3D />,
	},
	{
		path: '/3d',
		element: (
			<div className="h-screen">
				<Outlet />
			</div>
		),
		children: [
			{
				path: 'play3d',
				element: <Play3D />,
			},
			{
				path: 'stars',
				element: <StarsRoute />,
			},
			{
				path: 'threejs/home',
				element: <ThreeJSHomeRoute />,
			},
			{
				path: '3-basics',
				element: <ThreeJSBasicRoute />,
			},
			{
				path: 'threejs/text',
				element: <ThreeJSTextRoute />,
			},
			{
				path: 'threejs/galaxy',
				element: <ThreeJSGalaxyRoute />,
			},
		],
	},
	{
		path: '/games',
		element: (
			<div className="h-screen">
				<Outlet />
			</div>
		),
		children: [
			{
				path: 'jump',
				element: <GameJumpRoute />,
			},
		],
	},
	// catch-all route
	{
		path: '*',
		element: <CatchAllRoute />,
	},
]);
