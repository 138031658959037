import { useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import GUI from 'lil-gui';
import gsap from 'gsap';

declare global {
	var webgl: HTMLCanvasElement;
}

export const ThreeJSBasicRoute = () => {
	useEffect(() => {
		const scene = new THREE.Scene();
		const axesHelper = new THREE.AxesHelper(10);
		scene.add(axesHelper);

		const boxGeometry = new THREE.BoxGeometry(1, 1, 1, 2, 2, 2);
		const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: false });
		const box = new THREE.Mesh(boxGeometry, material);
		box.position.set(0.5, 0.5, 0.5);
		scene.add(box);

		// (fieldOfView id degree, aspectRatio, near, far)
		const camera = new THREE.PerspectiveCamera(75, 800 / 600, 0.1, 100);
		camera.position.set(4, 4, 4);
		scene.add(camera);
		camera.lookAt(new THREE.Vector3(0, 0, 0));

		// control
		const controls = new OrbitControls(camera, webgl);
		controls.enableDamping = true;

		// fix fps to 60
		// const clock = new THREE.Clock();

		// gsap anim
		// gsap.to(box.position, { duration: 1, delay: 1, x: 0 });

		const renderer = new THREE.WebGLRenderer({ canvas: webgl });
		renderer.setSize(800, 600);

		/* #region gui */
		const gui = new GUI();
		gui.add(box.position, 'x', -3, 3, 0.1).name('box.x'); // slider [min, max, step]
		gui.add(material, 'wireframe'); // checkbox
		gui.addColor(material, 'color'); // color picker

		const funcs = {
			spin: () => {
				gsap.to(box.rotation, { duration: 1, y: box.rotation.y + Math.PI * 2 });
			},
		};
		gui.add(funcs, 'spin'); // button
		/* #endregion */

		function render() {
			controls.update();

			renderer.render(scene, camera);
			requestAnimationFrame(render);
		}
		render();
	}, []);

	return (
		<div>
			<canvas id="webgl" />
		</div>
	);
};
