// @ts-nocheck

import { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GroupProps, useFrame } from '@react-three/fiber';

type Props = GroupProps & { speed?: number };

export function Planet2({ speed, ...props }: Props) {
	const { nodes, materials } = useGLTF('/3d/models/Planet_2.gltf');
	const ref = useRef<THREE.Group | null>(null);
	useFrame(({ clock }) => {
		ref.current!.rotation.y = clock.getElapsedTime() * 0.1 * (speed ?? 1);
	});
	return (
		<group {...props} ref={ref} dispose={null}>
			<mesh
				// castShadow
				// receiveShadow
				geometry={nodes.Planet_2.geometry}
				material={materials.Atlas}
			/>
		</group>
	);
}

useGLTF.preload('/3d/models/Planet_2.gltf');
