import { Canvas } from '@react-three/fiber';
import { MeshReflectorMaterial, PresentationControls, Stage } from '@react-three/drei';
import { AvatarMe } from './AvatarMe';
import { Chair3D } from './Chair.3d';
import { useQueryClient } from '@tanstack/react-query';
import { KEYS } from '@/services/query';
import { KG3D } from './KG.3d';

export const Experience = () => {
	return (
		<div className="h-full">
			<Canvas shadows camera={{ position: [0, 2, 5], fov: 75 }}>
				<color attach="background" args={['#010319']} />
				<ambientLight intensity={1} />
				<fog attach="fog" args={['#010319', 10, 50]} />

				{/* <OrbitControls /> */}
				<PresentationControls
					speed={0.5}
					global
					zoom={0.7}
					polar={[0.1, Math.PI / 4]} // y-axis limits [min, max]
				>
					<Stage environment="night" intensity={0.4} castShadow={false}>
						<group position-y={-1}>
							<group position-x={-1} rotation-x={-Math.PI / 2}>
								<AvatarMe />
							</group>
							<group>
								<Chair3D />
							</group>
							<group position-x={1}>
								<KG3D />
							</group>
							<ReflectionGround />
						</group>
					</Stage>
				</PresentationControls>
			</Canvas>
			<Configurator />
		</div>
	);
};

const actions = [
	{ title: 'Idle', action: () => {} },
	{ title: 'Dance', action: () => {} },
	{ title: 'Walk', action: () => {} },
];

const Configurator = () => {
	const queryClient = useQueryClient();
	return (
		<div className="fixed bottom-5 right-5 w-[320px] space-y-4 rounded-md bg-[#eeeeee20] p-4 text-white">
			<h1 className="text-md font-semibold uppercase tracking-wider">Animate</h1>
			<div className="flex flex-wrap gap-2">
				{actions.map(({ title }) => (
					<button
						className="min-w-[70px] rounded-md bg-[#eeeeee20] p-2"
						onClick={() => {
							queryClient.setQueryData(KEYS.meAction, title);
						}}
					>
						{title}
					</button>
				))}
			</div>
		</div>
	);
};

const ReflectionGround = () => (
	<mesh rotation={[-Math.PI / 2, 0, 0]}>
		<planeGeometry args={[170, 170]} />
		<MeshReflectorMaterial
			blur={[300, 300]}
			mirror={1}
			resolution={2048}
			mixBlur={1}
			mixStrength={40}
			roughness={1}
			depthScale={1.2}
			minDepthThreshold={0.4}
			maxDepthThreshold={1.4}
			color={'#505050'}
			metalness={0.5}
		/>
	</mesh>
);
