import { useEffect } from 'react';
import * as THREE from 'three';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import GUI from 'lil-gui';
import gsap from 'gsap';

export const ThreeJSTextRoute = () => {
	useEffect(() => {
		const scene = new THREE.Scene();
		const gui = new GUI();

		// const textureLoader = new THREE.TextureLoader();
		// const matcapTexture = textureLoader.load('/3d/texture/matcaps/3.png');
		// const mainMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
		const mainMaterial = new THREE.MeshNormalMaterial();

		const fontLoader = new FontLoader();
		fontLoader.load('/3d/font/helvetiker_regular.typeface.json', (font) => {
			const bevelSize = 0.02,
				bevelThickness = 0.03;

			const textGeometry = new TextGeometry('FRUSTUM CULLING', {
				font,
				size: 0.5,
				height: 0.2,
				curveSegments: 12, // will diff when matcap material is applied
				bevelEnabled: true,
				bevelThickness,
				bevelSize,
				bevelOffset: 0,
				bevelSegments: 5, // will diff when matcap material is applied
			});

			// bounding
			// textGeometry.computeBoundingBox();
			// textGeometry.translate(
			// 	-(textGeometry.boundingBox!.max.x - bevelSize) * 0.5,
			// 	-(textGeometry.boundingBox!.max.y - bevelSize) * 0.5,
			// 	-(textGeometry.boundingBox!.max.z - bevelThickness) * 0.5,
			// );
			// textGeometry.computeBoundingBox();
			// console.log(textGeometry.boundingBox);
			textGeometry.center();

			gui.add(mainMaterial, 'wireframe'); // checkbox
			const text = new THREE.Mesh(textGeometry, mainMaterial);
			scene.add(text);
		});

		const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);
		for (let i = 0; i < 150; i++) {
			const donut = new THREE.Mesh(donutGeometry, mainMaterial);
			donut.position.set(
				(Math.random() - 0.5) * 20,
				(Math.random() - 0.5) * 10,
				(Math.random() - 0.5) * 5,
			);
			donut.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI);
			const scale = Math.random();
			donut.scale.set(scale, scale, scale);
			scene.add(donut);
		}

		// (fieldOfView id degree, aspectRatio, near, far)
		const camera = new THREE.PerspectiveCamera(75, 800 / 600, 0.1, 100);
		camera.position.set(1, 0.5, 6);
		scene.add(camera);
		camera.lookAt(new THREE.Vector3(0, 0, 0));

		// control
		const controls = new OrbitControls(camera, webgl);
		controls.minDistance = 0.3;
		controls.maxDistance = 20;
		controls.enableDamping = true;

		const renderer = new THREE.WebGLRenderer({ canvas: webgl });
		renderer.setSize(800, 600);

		/* #region gui */
		const guiActions = {
			zoomIn: () => {
				camera.zoom = 2;
				camera.updateProjectionMatrix();
			},
			zoomOut: () => {
				camera.zoom = 1;
				camera.updateProjectionMatrix();
			},
			zoomInAnimation: () => {
				gsap.to(camera, {
					zoom: 2,
					duration: 1,
					delay: 1,
					onUpdate: function () {
						camera.updateProjectionMatrix();
					},
				});
			},
		};
		gui.add(guiActions, 'zoomIn');
		gui.add(guiActions, 'zoomOut');
		gui.add(guiActions, 'zoomInAnimation');
		/* #endregion */

		let animator = 0;
		function render() {
			renderer.render(scene, camera);
			animator = requestAnimationFrame(render);
		}
		guiActions.zoomInAnimation();
		render();
		return () => {
			cancelAnimationFrame(animator);
		};
	}, []);

	return (
		<div>
			<canvas id="webgl" />
		</div>
	);
};
