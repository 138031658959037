import {
	Environment,
	PresentationControls,
	Float,
	ContactShadows,
	useGLTF,
} from '@react-three/drei';

export const MacResumeExperience = () => {
	return (
		<>
			<Environment preset="city" />
			<ambientLight intensity={0.5} />

			<PresentationControls
				global
				rotation={[0.1, 0.1, 0]}
				polar={[-0.4, 0.2]}
				azimuth={[-1, 0.25]}
				snap={{ mass: 2, tension: 500 }}
			>
				<Float rotationIntensity={0.3}>
					<MacModel position={[-0.2, -1.2, 0]} />
				</Float>
			</PresentationControls>

			<ContactShadows position-y={-1.4} opacity={0.4} blur={2.4} scale={5} />
		</>
	);
};

function MacModel(props: any) {
	const { scene } = useGLTF(
		'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf',
	);
	return <primitive object={scene} {...props} />;
}
