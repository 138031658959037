import ReactDOM from 'react-dom/client';

import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import './index.css';
import { QueryProvider } from './services/query';

ReactDOM.createRoot(document.getElementById('root')!).render(
	<QueryProvider>
		<RouterProvider router={router} />
	</QueryProvider>,
);
