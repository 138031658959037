import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useScroll } from '@react-three/drei';
import { createPortal } from 'react-dom';

const interval = 0.75;
const Section = ({ children, idx }: PropsWithChildren<{ idx: number; debug?: boolean }>) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const params = useRef({ visible: false });
	const [visible, setVisible] = useState(false);
	const scroll = useScroll();

	useFrame(() => {
		const newVisible = scroll.offset >= idx / 4 && scroll.offset <= (idx + interval) / 4;
		if (newVisible !== params.current.visible) {
			params.current.visible = newVisible;
			setVisible(params.current.visible);
		}
	});

	return (
		<div className="fixed flex h-screen w-screen translate-x-1 items-center justify-center">
			<div
				ref={ref}
				className={`flex h-1/3 flex-col items-center justify-center transition-all duration-1000 ${
					visible ? 'opacity-100' : 'scale-0 opacity-0'
				}`}
			>
				{children}
			</div>
		</div>
	);
};

const Icon = {
	ChevronDown: () => (
		<svg
			viewBox="0 0 1024 1024"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
		>
			<path
				d="M491.52 725.312a45.632 45.632 0 0 1-35.648-15.744L143.296 387.84c-20.416-20.992-20.416-54.208 0-73.408 20.352-20.992 52.672-20.992 71.36 0l275.2 283.264 276.864-283.264c20.352-20.992 52.608-20.992 71.296 0 20.416 20.928 20.416 54.208 0 73.408l-310.848 321.728c-10.176 8.768-23.744 15.744-35.648 15.744z"
				fill="#ffffffc0"
				p-id="4116"
			></path>
		</svg>
	),
	LinkedIn: () => (
		<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
			<path
				d="M542.165333 439.125333A128 128 0 0 1 725.333333 554.666667v128a42.666667 42.666667 0 0 1-85.333333 0v-128a42.666667 42.666667 0 0 0-85.333333 0v128a42.666667 42.666667 0 0 1-85.333334 0v-213.333334a42.666667 42.666667 0 0 1 72.832-30.208zM213.333333 810.666667h469.333334a42.666667 42.666667 0 0 1 0 85.333333H170.666667a42.666667 42.666667 0 0 1-42.666667-42.666667V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667h682.666666a42.666667 42.666667 0 0 1 42.666667 42.666667v682.666666a42.666667 42.666667 0 0 1-85.333333 0V213.333333H213.333333v597.333334z m85.333334-341.333334a42.666667 42.666667 0 0 1 85.333333 0v213.333334a42.666667 42.666667 0 0 1-85.333333 0v-213.333334z m42.666666-85.333333a42.666667 42.666667 0 1 1 0-85.333333 42.666667 42.666667 0 0 1 0 85.333333z"
				fill="#ffffffcc"
			></path>
		</svg>
	),
};

const myStackSectionData = [
	{
		title: 'Frontend',
		skills: ['React', 'Three.js', 'Next.js', 'Remix.js', 'MERN', 'Typescript'],
	},
	{
		title: 'Mobile',
		skills: ['Objective-C/Swift', 'ADK/Java/Kotlin', 'React Native', 'App Store/Google Play'],
	},
	{
		title: 'Other',
		skills: [
			'Git',
			'GitHub Actions/CircleCI',
			'Docker',
			'Shopify',
			'Cocos Creator',
			'Web3',
			'Figma',
		],
	},
];

const portfolioSectionData = [
	{
		title: 'Game: A Wandering Mind',
		tags: ['Cocos Creator', 'Typescript'],
		previewImageUrl: '/img/wandering-mind.png',
		demos: [
			{
				title: 'web',
				icon: 'web',
				url: 'https://chang.is-a.dev/dh_hack2022',
			},
			{
				title: 'android apk',
				icon: 'android',
				url: 'https://github.com/bobbyliu117/dh_hack2022/releases/download/v0.1.0/dh_hack2022-release.apk',
			},
			{
				title: 'GitHub',
				icon: 'github',
				url: 'https://github.com/bobbyliu117/dh_hack2022',
			},
		],
	},
];

export const IntroSections = () => {
	useLayoutEffect(() => {
		// FIXME: this hackfix clears multiple portal attaches when refresh the page
		while (portal3d.children.length > 1) {
			portal3d.removeChild(portal3d.children[0]);
		}
	}, []);
	return createPortal(
		<div className="absolute left-0 top-0">
			<Section idx={0}>
				<div className="text-3xl font-bold">Hi, this is Chang.</div>
				<div className="mt-2 text-2xl">I ❤️ coding.</div>
				<div className="mt-4 animate-bounce text-3xl font-bold">
					<Icon.ChevronDown />
				</div>
			</Section>
			<Section idx={1} debug>
				<div className="text-3xl font-bold">My Stacks</div>
				<div className="container mt-4 space-y-4 px-4">
					{myStackSectionData.map(({ title, skills }) => {
						return (
							<div>
								<h2 className="text-xl">{title}</h2>
								<div className="space-y-2">
									{skills.map((skill) => (
										<div key={skill} className="btn btn-outline pointer-events-auto mr-2">
											{skill}
										</div>
									))}
								</div>
							</div>
						);
					})}
				</div>
			</Section>
			<Section idx={2}>
				<div className="text-3xl font-bold">My Works</div>
				<div className="mt-4 space-y-4">
					{portfolioSectionData.map(({ title, tags, previewImageUrl, demos }) => {
						return (
							<div className="flex flex-col gap-4 sm:flex-row">
								<div className="max-w-xs flex-1 overflow-hidden rounded-xl border-2">
									<img src={previewImageUrl} />
								</div>

								<div>
									<h2 className="text-xl font-bold">{title}</h2>
									<div className="space-y-2">
										{tags.map((tag) => (
											<div key={tag} className="badge badge-accent mr-2 rounded-full">
												{tag}
											</div>
										))}
									</div>
									<div className="mt-4 space-x-4">
										{demos.map(({ title, url }) => (
											<a key={title} href={url} target="_blank">
												<div className="btn btn-outline pointer-events-auto">{title}</div>
											</a>
										))}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</Section>
			<Section idx={3}>
				<div className="mb-4 text-3xl font-bold">...and more</div>
				{/* <DebugNavs to={to} /> */}
				<div className="min-w-[60vw]">
					{[
						{
							title: 'SCE Media',
							path: 'https://sce.boobareth.com/',
						},
						{
							title: 'GameFi: 3K NFT',
							path: 'https://changliu.is-a.dev/bs-game-front/',
						},
						{
							title: 'ETH Name Service',
							path: 'https://changliu.is-a.dev/bs-ens-front/',
						},
						{
							title: 'DAO (Web3)',
							path: 'https://changliu.is-a.dev/bs-dao/',
						},
						{
							title: 'Coolio',
							path: 'https://659e510767d89dbb6ee00012--capable-starburst-fbae0e.netlify.app/',
						},
						{
							title: 'Remix Shop (WIP)',
							path: 'https://boobar-remix.pages.dev',
						},
					].map(({ title, path }) => (
						<a
							key={title}
							href={path}
							target="_blank"
							className="btn btn-link pointer-events-auto w-fit text-lg text-gray-300 underline-offset-4 hover:text-white"
						>
							{title}
						</a>
					))}
				</div>
			</Section>
			<Section idx={4}>
				<div className="text-3xl font-bold">Let's connect!</div>
				<div className="mt-8 transition-all hover:scale-110">
					<a
						target="_blank"
						href="https://www.linkedin.com/in/bobbychangliu/"
						className="pointer-events-auto"
					>
						<Icon.LinkedIn />
					</a>
				</div>
			</Section>
		</div>,
		portal3d,
	);
};
