// @ts-nocheck

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import type { GroupProps } from '@react-three/fiber';
import * as THREE from 'three';

export function Chair3D(props: GroupProps) {
	const { nodes, materials } = useGLTF('/3d/chair.glb');
	const leatherTexture = useTexture({
		// map: '/3d/texture/leather008/Leather_008_Base Color.jpg',
		normalMap: '/3d/texture/leather008/Leather_008_Normal.jpg',
		roughnessMap: '/3d/texture/leather008/Leather_008_Roughness.jpg',
		aoMap: '/3d/texture/leather008/Leather_008_Ambient Occlusion.jpg',
	});

	const fabricTextureProps = useTexture({
		normalMap: '/3d/texture/fabric-knitted-006/Fabric_Knitted_006_normal.jpg',
		roughnessMap: '/3d/texture/fabric-knitted-006/Fabric_Knitted_006_roughness.jpg',
		aoMap: '/3d/texture/fabric-knitted-006/Fabric_Knitted_006_ambientOcclusion.jpg',
	});

	// Fix texture stretching
	Object.keys(leatherTexture).forEach((key) => {
		leatherTexture[key].wrapS = leatherTexture[key].wrapT = THREE.RepeatWrapping;
		leatherTexture[key].repeat.set(3, 3);
	});
	Object.keys(fabricTextureProps).forEach((key) => {
		fabricTextureProps[key].wrapS = fabricTextureProps[key].wrapT = THREE.RepeatWrapping;
		fabricTextureProps[key].repeat.set(3, 3);
	});

	return (
		<group {...props} dispose={null}>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Chair.geometry}
				material={materials.Chair}
				position={[0, 0.011, 0.008]}
				scale={0.318}
			>
				<meshStandardMaterial {...leatherTexture} color="tomato" />
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Cushion.geometry}
				material={materials.Cushion}
				position={[0, 0.031, 0.022]}
				scale={0.318}
			>
				<meshStandardMaterial {...fabricTextureProps} color="moccasin" />
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Legs1.geometry}
				material={materials.Legs}
				position={[0, 0.011, 0.008]}
				scale={0.318}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Legs2.geometry}
				material={materials.Legs}
				position={[0, 0.011, 0.008]}
				scale={0.318}
				visible={false}
			/>
		</group>
	);
}

useGLTF.preload('/3d/chair.glb');
