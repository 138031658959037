// @ts-nocheck

import { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GroupProps, useFrame } from '@react-three/fiber';

type Props = GroupProps & { speed?: number };

export function Moon3D({ speed, ...props }: Props) {
	const { nodes, materials } = useGLTF('/3d/models/moon.glb');

	const ref = useRef<THREE.Group | null>(null);
	useFrame(({ clock }) => {
		ref.current!.rotation.y = clock.getElapsedTime() * 0.1 * (speed ?? 1);
	});

	return (
		<group {...props} ref={ref} dispose={null}>
			<group scale={1.155}>
				<group rotation={[-Math.PI / 2, 0, 0]}>
					<mesh
						// castShadow
						// receiveShadow
						geometry={nodes.defaultMaterial.geometry}
						material={materials.Material__50}
						rotation={[Math.PI / 2, 0, 0]}
					/>
				</group>
			</group>
		</group>
	);
}

useGLTF.preload('/3d/models/moon.glb');
