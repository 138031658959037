import { useNavigate } from 'react-router-dom';
import styles from './home.module.css';
import { useFullScreenCanvas } from '@/utils/hooks/useFullScreenCanvas';
import { useEffect } from 'react';
import * as THREE from 'three';

export const ThreeJSHomeRoute = () => {
	const navigate = useNavigate();
	const size = useFullScreenCanvas();

	useEffect(() => {
		let requestAnimationFrameId = 0;

		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, size.sw / size.sh, 0.1, 100);
		camera.position.z = 3;
		scene.add(camera);

		const geometry = new THREE.BoxGeometry();
		const material = new THREE.MeshNormalMaterial();
		const cube = new THREE.Mesh(geometry, material);
		scene.add(cube);

		const renderer = new THREE.WebGLRenderer({
			canvas: webgl,
			alpha: true,
		});

		renderer.setSize(size.sw, size.sh);

		function render() {
			renderer.render(scene, camera);
			requestAnimationFrameId = requestAnimationFrame(render);
		}

		render();

		return () => {
			cancelAnimationFrame(requestAnimationFrameId);
		};
	}, [size]);

	return (
		<div>
			<canvas id="webgl" className={styles.canvas} width={size.sw} height={size.sw} />
			<div className={styles.links}>
				{['/3d/3-basics', '/3d/threejs/text', '/3d/threejs/galaxy'].map((path) => {
					return (
						<div className="flex h-screen justify-center">
							<button key={path} onClick={() => navigate(path)}>
								{path}
							</button>
						</div>
					);
				})}
			</div>
		</div>
	);
};
