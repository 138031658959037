import { FontLoader, Font } from 'three/addons/loaders/FontLoader.js';

const FONT_MAP = {
	helvetiker: '/3d/font/helvetiker_regular.typeface.json',
} as const;

const fontMemo = {
	helvetiker: null as Font | null,
};

const loader = new FontLoader();

export const load3DFont = async (fontKey: keyof typeof FONT_MAP) => {
	return new Promise<Font>((resolve, reject) => {
		if (fontMemo[fontKey]) {
			return resolve(fontMemo[fontKey]!);
		}
		loader.load(
			FONT_MAP[fontKey],
			(font) => {
				fontMemo[fontKey] = font;
				resolve(font);
			},
			undefined,
			(err) => {
				reject(err);
			},
		);
	});
};
