import { Stars3D } from '@/components/Stars.3d';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';

export const StarsRoute = () => {
	return (
		<div
			className="h-screen"
			style={{
				background: 'linear-gradient(180deg, #010319 0%, #030957 50%, rgba(1,3,25,0.5) 100%)',
			}}
		>
			<Canvas camera={{ position: [0, 0, 1] }}>
				<OrbitControls />
				<Stars3D />
			</Canvas>
		</div>
	);
};
